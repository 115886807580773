import { Prisma } from "@prisma/client";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

const keyFactory = {
  login: (username: string, password: string) => ["login", username, password],
  registerCandidate: (data: Prisma.UserCreateInput) => [
    "registerCandidate",
    data,
  ],
};

const logIn = async (username: string, password: string) => {
  const response = await axios.post(`/auth/login`, {
    username,
    password,
  });
  return response.data;
};

export const useLogInQuery = (username: string, password: string) => {
  return useQuery({
    queryKey: keyFactory.login(username, password),
    queryFn: () => logIn(username, password),
  });
};

const registerCandidate = (userData: Prisma.UserCreateInput) => {
  return axios.post(`/auth/register/candidate`, userData);
};

export interface UseRegisterUserMutationOptions {
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
}

export const useRegisterUserMutation = ({
  onSuccess,
  onError,
}: UseRegisterUserMutationOptions = {}) => {
  return useMutation({
    mutationFn: (userData: Prisma.UserCreateInput) =>
      registerCandidate(userData),
    onSuccess,
    onError,
  });
};
