import logo from "@/assets/logo.png";
import { Button } from "../atoms/Button";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "@/constants/routes";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="m-10">
        <img
          className="flex justify-center items-center"
          src={logo}
          alt="Description of the image"
        />
      </div>
      <h1>404</h1>
      <div>Page inconnue.</div>
      <Button onClick={() => navigate(ROUTE.home)}>
        <div>Retourner à l'accueil</div>
      </Button>
    </div>
  );
};

export default NotFound;
