import logo from "@/assets/logo.png";
import { Button } from "@/components/atoms/Button";
import { RadioGroup, RadioGroupItem } from "@/components/atoms/RadioGroup";
import { ROUTE } from "@/constants/routes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../atoms/Tabs";
import BackButton from "../molecules/BackButton";

const CandidateCreateAccount = () => {
  const [hasCertificate, setHasCertificate] = useState(false);

  const navigate = useNavigate();
  return (
    <div>
      <h1 className="mt-8">Créer un compte candidat</h1>
      <div className="font-semibold mt-8">Sélectionnez votre situation</div>
      <RadioGroup
        defaultValue="false"
        onValueChange={(str) => setHasCertificate(str === "true")}
        className="mt-2"
      >
        {[
          {
            value: "false",
            msg: "Je ne possède pas de certificat",
            disabled: false,
            key: "false",
          },
          {
            value: "true",
            msg: "Je possède un certificat d'un autre état",
            disabled: true,
            key: "true",
          },
        ].map(({ value, msg, disabled, key }) => (
          <div className="flex items-center self-stretch" key={key}>
            <RadioGroupItem
              key={value}
              value={value}
              disabled={disabled}
              className="flex items-center justify-center gap-2"
            />
            <div>{msg}</div>
          </div>
        ))}
      </RadioGroup>
      <div className="flex flex-col gap-2 items-start self-stretch">
        <Button
          className="flex px-1 py-2 justify-center gap-1 self-stretch rounded-lg border border-[#1D64D8] bg-[#2570EB] mt-7"
          onClick={() =>
            navigate(ROUTE.candidate.accountCreation, {
              state: { hasCertificate },
            })
          }
        >
          Suivant
        </Button>
      </div>
    </div>
  );
};

const CandidateConnect = () => {
  return <div>TODO</div>;
};

const CompanyCreateAccount = () => {
  return <div>TODO</div>;
};

const CompanyConnect = () => {
  return <div>TODO</div>;
};

const Home = () => {
  return (
    <div className="flex flex-col items-center mt-16 h-screen">
      <BackButton />
      <img
        className="flex justify-center items-center mb-12"
        src={logo}
        alt="Description of the image"
      />
      <div className="flex flex-col items-start p-8 rounded-lg border border-gray-200 bg-white shadow-sm">
        <Tabs defaultValue="register">
          <TabsList className="flex px-0 py-0.5" defaultValue="register">
            <TabsTrigger
              value="connect"
              className="flex m-0.5 w-full h-full items-center justify-center"
              disabled={true}
            >
              Déjà un compte
            </TabsTrigger>
            <TabsTrigger
              value="register"
              className="flex m-0.5 w-full h-full items-center justify-center"
            >
              Créer un compte
            </TabsTrigger>
          </TabsList>
          <TabsContent value="connect">
            <Tabs defaultValue="candidate">
              <TabsList className="bg-transparent">
                <TabsTrigger
                  className="rounded-none data-[state=active]:bg-transparent data-[state=active]:text-blue-700 border-b data-[state=active]:border-b-2 data-[state=active]:border-blue-700"
                  value="candidate"
                >
                  Je suis un candidat
                </TabsTrigger>
                <TabsTrigger
                  className="rounded-none data-[state=active]:bg-transparent data-[state=active]:text-blue-700 border-b data-[state=active]:border-b-2 data-[state=active]:border-blue-700"
                  value="company"
                >
                  Je suis une entreprise
                </TabsTrigger>
              </TabsList>
              <TabsContent value="candidate">
                <CandidateConnect />
              </TabsContent>
              <TabsContent value="company">
                <CompanyConnect />
              </TabsContent>
            </Tabs>
          </TabsContent>
          <TabsContent value="register">
            <Tabs defaultValue="candidate">
              <TabsList className="bg-transparent">
                <TabsTrigger
                  className="rounded-none data-[state=active]:bg-transparent data-[state=active]:text-blue-700 border-b data-[state=active]:border-b-2 data-[state=active]:border-blue-700"
                  value="candidate"
                >
                  Je suis un candidat
                </TabsTrigger>
                <TabsTrigger
                  value="company"
                  className="rounded-none data-[state=active]:bg-transparent data-[state=active]:text-blue-700 border-b data-[state=active]:border-b-2 data-[state=active]:border-blue-500"
                  disabled={true}
                >
                  Je suis une entreprise
                </TabsTrigger>
              </TabsList>
              <TabsContent value="candidate">
                <CandidateCreateAccount />
              </TabsContent>
              <TabsContent value="company">
                <CompanyCreateAccount />
              </TabsContent>
            </Tabs>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default Home;
