import logo from "@/assets/logo.png";
import { Button } from "@/components/atoms/Button";
import BackButton from "@/components/molecules/BackButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { CircleAlert, LucideChevronLeft } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { useRegisterUserMutation } from "../../../../api/auth";
import { ROUTE } from "../../../../constants/routes";
import { Form } from "../../../atoms/Form";
import { useToast } from "../../../atoms/Toast/UseToast";
import AddressForm from "./AddressForm/AddressForm";
import {
  addressDefaultValues,
  addressSchema,
} from "./AddressForm/AddressFormSchema";
import ForeignCertificateForm from "./ForeignCertificateForm/ForeignCertificateForm";
import PersonnalInformation from "./PersonnalInfoForm/PersonnalInfoForm";
import {
  personnalInfoDefaultValues,
  personnalInfoSchema,
} from "./PersonnalInfoForm/PersonnalInfoFormSchema";

const accountCreationSchema = z.object({
  address: addressSchema,
  personnalInfo: personnalInfoSchema,
});

const TopText = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-1 items-start">
      <Button
        className="p-0 m-0 bg-transparent text-gray-800 gap-1.5 hover:text-brand-700 hover:bg-transparent text-xs"
        onClick={() => navigate(-1)}
      >
        <LucideChevronLeft />
        Retour à la page précédente
      </Button>
      <h1 className="mt-1.5">Création du compte</h1>
      <p className="mt-1">
        Complétez les différents champs pour la création de votre compte.
        <span className="text-brand-600 font-bold">
          {" "}
          Les champs marqués d'un astérisque *{" "}
        </span>
        figureront sur votre certificat : ils doivent être soigneusement
        vérifiés et correctement renseignés.
      </p>
    </div>
  );
};

const ErrorDisplay = ({ msg }: { msg: string }) => {
  return (
    <div className="flex space-x-2 p-2 items-start gap-2 self-stretch rounded-lg border border-gray-300 bg-white">
      <CircleAlert className="text-red-500" />
      <div className="flex flex-col">
        <div className="font-bold">Erreur</div>
        <div className="text-gray-600">{msg}</div>
      </div>
    </div>
  );
};

const AccountCreation = () => {
  const { hasCertificate } = (useLocation().state || false) as {
    hasCertificate: boolean;
  };

  const { toast } = useToast();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");

  const registerUserMutation = useRegisterUserMutation({
    onSuccess: () => {
      navigate(ROUTE.candidate.myExams);
      toast({
        title: "Votre compte a bien été créé.",
        description: "Vous pouvez maintenant y accéder.",
      });
    },
    onError: (error) => {
      const errorData = error.response?.data as { error?: string } | undefined;

      if (errorData?.error === "Email already in use") {
        setErrorMsg("Cet email est déjà utilisé.");
        console.error("user tried to register with an existing email");
        return;
      }

      setErrorMsg(
        "Une erreur est survenue lors de la création de votre compte."
      );
      console.error("error while creating account : ", error);
      console.error(error.response?.data);
    },
  });

  const form = useForm<z.infer<typeof accountCreationSchema>>({
    resolver: zodResolver(accountCreationSchema),
    defaultValues: {
      address: addressDefaultValues,
      personnalInfo: personnalInfoDefaultValues,
    },
  });

  function onSubmit(values: z.infer<typeof accountCreationSchema>) {
    const { data, error, success } = accountCreationSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting registration :", error);
      return;
    }

    console.log("trying to register user with info : ", data);

    registerUserMutation.mutate({
      email: data.personnalInfo.email,
      key: data.personnalInfo.password,
      role: "CANDIDATE",
      candidate: {
        create: {
          sex: data.personnalInfo.sex,
          lastName: data.personnalInfo.lastName,
          firstName: data.personnalInfo.firstName,
          birthdate: data.personnalInfo.birthdate,
          birthCity: data.personnalInfo.birthCity,
          birthCountry: data.personnalInfo.birthCountry,
          nationality: data.personnalInfo.nationality,
          homePhone: data.personnalInfo.homePhone,
          mobilePhone: data.personnalInfo.mobilePhone,
          personnalAddress: data.address.personnalAddress,
          postalCode: data.address.postalCode,
          city: data.address.city,
          complement: data.address.complement,
          country: data.address.country,
        },
      },
    });
  }

  return (
    <div className="flex flex-col items-center mt-16 min-h-screen">
      <BackButton />
      <img
        className="flex justify-center items-center mb-12"
        src={logo}
        alt="Description of the image"
      />
      <div className="max-w-3xl flex flex-col items-start px-10 py-8 rounded-lg border border-gray-200 bg-white shadow-sm gap-8 mb-32">
        <TopText />
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full flex-col space-y-8"
          >
            <PersonnalInformation />
            {hasCertificate && <ForeignCertificateForm />}
            <AddressForm />
            <Button
              className="flex w-full font-semibold bg-brand-600 text-white"
              type="submit"
              disabled={registerUserMutation.isPending}
            >
              {registerUserMutation.isPending
                ? "Création de votre compte..."
                : "Créer mon compte"}
            </Button>
            {registerUserMutation.isError && <ErrorDisplay msg={errorMsg} />}
          </form>
        </Form>
      </div>
    </div>
  );
};

export default AccountCreation;
