import { Sex } from "@/constants/enums/Sex";
import { isValidPhoneNumber } from "react-phone-number-input";
import { z } from "zod";

const mandatoryString = () =>
  z
    .string()
    .min(1, { message: "Champ obligatoire" })
    .max(50, { message: "50 caractères maximum" })
    .trim();

const optionnalString = () => mandatoryString().or(z.literal(""));

const email = () =>
  z
    .string()
    .min(1, { message: "Champ obligatoire" })
    .email({ message: "Veuillez entrer une adresse email valide" })
    .trim();

const password = () =>
  z
    .string()
    .min(1, { message: "Champ obligatoire" })
    .min(8, { message: "Le mot de passe doit contenir au moins 8 caractères" })
    .max(50, {
      message: "Le mot de passe doit contenir au plus 100 caractères",
    })
    .trim();

const optionalPhone = () =>
  z.string().refine((phone) => phone == "" || isValidPhoneNumber(phone), {
    message: "Le numéro de téléphone doit être valide",
  });

const personnalInfoSchema = z
  .object({
    sex: z.enum([Sex.MALE, Sex.FEMALE]),
    lastName: mandatoryString(),
    firstName: mandatoryString(),
    birthdate: z
      .date({ message: "Mauvais format (attendu JJ/MM/AAAA)" }) //
      .min(new Date("1900-01-01"), {
        message: "Veuillez choisir votre vrai date de naissance",
      })
      .max(new Date(), "Veuillez choisir votre vrai date de naissance"),
    birthCity: mandatoryString().transform(
      (city) => city[0].toUpperCase() + city.slice(1).toLowerCase()
    ),
    birthCountry: optionnalString(),
    nationality: mandatoryString(),
    email: email(),
    emailConfirmation: email(),
    password: password(),
    passwordConfirmation: z.string(),
    homePhone: optionalPhone(),
    mobilePhone: optionalPhone(),
  })
  .superRefine(
    ({ password, passwordConfirmation, email, emailConfirmation }, ctx) => {
      if (password != passwordConfirmation) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Les mots de passe ne correspondent pas",
          path: ["passwordConfirmation"],
        });
      }

      if (email != emailConfirmation) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Les adresses mail ne correspondent pas",
          path: ["emailConfirmation"],
        });
      }
    }
  );

const personnalInfoDefaultValues: z.infer<typeof personnalInfoSchema> = {
  sex: Sex.FEMALE,
  lastName: "",
  firstName: "",
  birthdate: new Date(),
  birthCity: "",
  birthCountry: "",
  nationality: "",
  email: "",
  emailConfirmation: "",
  password: "",
  passwordConfirmation: "",
  homePhone: "",
  mobilePhone: "",
};

export { personnalInfoSchema, personnalInfoDefaultValues };
