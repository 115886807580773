import { Input } from "@/components/atoms/Input";
import { Label } from "@/components/atoms/Label";

function InputField({
  title,
  placeholder,
  setter,
}: {
  title: string;
  placeholder: string;
  setter: (value: string) => void;
}) {
  return (
    <div className="flex flex-col gap-1 self-stretch items-start">
      <Label>{title}</Label>
      <Input
        className="shadow"
        placeholder={placeholder}
        onChange={(e) => setter(e.target.value)}
      />
    </div>
  );
}

export default InputField;
