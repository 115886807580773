import { z } from "zod";

const optionnalString = () =>
  z
    .string()
    .min(2, { message: "2 caractères minimum" })
    .max(50, { message: "50 caractères maximum" })
    .trim()
    .or(z.literal(""));

const addressSchema = z.object({
  country: optionnalString(),
  personnalAddress: optionnalString(),
  complement: optionnalString(),
  postalCode: optionnalString(),
  city: optionnalString(),
});

const addressDefaultValues: z.infer<typeof addressSchema> = {
  country: "",
  personnalAddress: "",
  complement: "",
  postalCode: "",
  city: "",
};

export { addressDefaultValues, addressSchema };
