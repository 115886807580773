import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import { RadioGroup, RadioGroupItem } from "@/components/atoms/RadioGroup";
import { ComboboxField } from "@/components/molecules/Combobox";
import DatePicker from "@/components/molecules/DatePicker";
import { countries } from "@/constants/countries";
import { useFormContext } from "react-hook-form";
import { PhoneInput } from "../../../../molecules/PhoneInput";
import { Sex } from "@/constants/enums/Sex";

interface FormRadioProps {
  value: string;
  onChange: (s: string) => void;
  options: Array<{ label: string; value: string }>;
}

const FormRadio = ({ value, onChange, options }: FormRadioProps) => {
  return (
    <RadioGroup
      defaultValue={value}
      onValueChange={onChange}
      className="flex flex-row gap-4"
    >
      {options.map(({ label, value }) => (
        <FormItem className="flex items-center self-stretch pr-14" key={value}>
          <FormControl>
            <div className="flex items-center">
              <RadioGroupItem
                id={value}
                value={value}
                className="flex items-center justify-center"
              />
              <FormLabel htmlFor={value}>{label}</FormLabel>
            </div>
          </FormControl>
        </FormItem>
      ))}
    </RadioGroup>
  );
};

const formatOptionList = (options: string[]) => {
  const l = options.map(
    (option) => option.charAt(0).toUpperCase() + option.slice(1)
  );
  l.sort();
  return l;
};

const PersonnalInformation = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const rowClasses = "flex flex-row gap-2 w-full mt-1";

  return (
    <div className="flex flex-col w-full">
      <h2>Informations personnelles</h2>
      <div className="flex flex-col gap-1">
        <div className={rowClasses + " py-2"}>
          <FormField
            control={control}
            name="personnalInfo.sex"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <FormRadio
                    value={field.value}
                    onChange={field.onChange}
                    options={[
                      { value: Sex.MALE, label: "Monsieur" },
                      { value: Sex.FEMALE, label: "Madame" },
                    ]}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
        <div className={rowClasses}>
          <FormField
            control={control}
            name="personnalInfo.lastName"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Nom *</FormLabel>
                <FormControl>
                  <Input
                    placeholder={field.value || "-"}
                    {...field}
                    error={errors.lastName !== undefined}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="personnalInfo.firstName"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Prénom *</FormLabel>
                <FormControl>
                  <Input
                    placeholder={field.value || "-"}
                    {...field}
                    error={errors.firstName !== undefined}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className={rowClasses}>
          <FormField
            control={control}
            name="personnalInfo.birthdate"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Date de naissance *</FormLabel>
                <DatePicker
                  value={field.value}
                  onChange={field.onChange}
                  restrictToToday={true}
                />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="personnalInfo.birthCity"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Ville de naissance *</FormLabel>
                <FormControl>
                  <Input
                    placeholder={field.value || "Constantine"}
                    {...field}
                    error={errors.birthCity !== undefined}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className={rowClasses}>
          <FormField
            control={control}
            name="personnalInfo.birthCountry"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Pays de naissance</FormLabel>
                <FormControl>
                  <ComboboxField
                    options={formatOptionList(
                      countries.map((country) => country.libelle)
                    ).map((option) => ({
                      value: option,
                      label: option,
                    }))}
                    placeholder={field.value || "-"}
                    value={field.value}
                    onChange={field.onChange}
                    optionnal={true}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="personnalInfo.nationality"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Nationalité *</FormLabel>
                <FormControl>
                  <ComboboxField
                    options={formatOptionList(
                      countries.map((country) => country.nationalite)
                    ).map((option) => ({
                      value: option,
                      label: option,
                    }))}
                    placeholder={field.value || "-"}
                    value={field.value}
                    onChange={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className={rowClasses}>
          <FormField
            control={control}
            name="personnalInfo.email"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Adressse email *</FormLabel>
                <FormControl>
                  <Input
                    placeholder={field.value || "mail@mail.com"}
                    {...field}
                    error={errors.email !== undefined}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="personnalInfo.emailConfirmation"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Confirmation adresse email *</FormLabel>
                <FormControl>
                  <Input
                    placeholder={field.value || "mail@mail.com"}
                    {...field}
                    error={errors.emailConfirmation !== undefined}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className={rowClasses}>
          <FormField
            control={control}
            name="personnalInfo.password"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Mot de passe *</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    placeholder={field.value || "*****"}
                    {...field}
                    error={errors.password !== undefined}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="personnalInfo.passwordConfirmation"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Confirmation mot de passe *</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    placeholder={field.value || "*****"}
                    {...field}
                    error={errors.passwordConfirmation !== undefined}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className={rowClasses}>
          <FormField
            control={control}
            name="personnalInfo.homePhone"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Téléphone fixe</FormLabel>
                <FormControl>
                  <PhoneInput
                    placeholder={field.value || "04 79 37 28 28"}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="personnalInfo.mobilePhone"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Mobile</FormLabel>
                <FormControl>
                  <PhoneInput
                    // TODO default value to France
                    placeholder={field.value || "06 88 37 28 28"}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonnalInformation;
