import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, Link, RouterProvider } from "react-router-dom";
import "unfonts.css";
import Login from "./components/pages/Admin/Login/Login.tsx";
import AccountCreation from "./components/pages/Candidate/AccountCreation/AccountCreation.tsx";
import Home from "./components/pages/ConnectionHome.tsx";
import NotFound from "./components/pages/NotFound.tsx";
import { ROUTE } from "./constants/routes.tsx";
import "./styles/index.css";
import axios from "axios";
import MyExams from "./components/pages/Candidate/MyExams/MyExams.tsx";
import { Toaster } from "./components/atoms/Toast/Toaster.tsx";

const API_URL = import.meta.env.VITE_API_URL;
const API_PORT = import.meta.env.VITE_API_PORT;

axios.defaults.baseURL = `${API_URL}:${API_PORT}`;

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: ROUTE.home,
    element: (
      <div>
        Ceci est une page de test.
        <br /> Si elle s'affiche, c'est que le serveur fonctionne :)
        <br /> Pour tester, aller voir les pages (cliquer dessus) :
        <ul>
          {[
            {
              path: ROUTE.candidate.accountCreation,
              msg: "Création compte candidat",
            },
            { path: ROUTE.connectionHome, msg: "Accueil (NON FINIE)" },
            { path: ROUTE.admin.login, msg: "Login admin (NON FINIE)" },
          ].map(({ path, msg }) => (
            <li key={path}>
              <Link to={path}>{msg}</Link>
            </li>
          ))}
        </ul>
      </div>
    ),
  },
  {
    path: ROUTE.admin.login,
    element: <Login />,
  },
  {
    path: ROUTE.connectionHome,
    element: <Home />,
  },
  {
    path: ROUTE.candidate.accountCreation,
    element: <AccountCreation />,
  },
  {
    path: ROUTE.candidate.myExams,
    element: <MyExams />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <Toaster />
    </QueryClientProvider>
  </StrictMode>
);
