import { Button } from "@/components/atoms/Button";
import { ROUTE } from "@/constants/routes";
import { LucideChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      className="absolute top-8 left-20 inline-flex px-3.5 py-2.5 justify-center gap-1 border-2 bg-white text-foreground rounded-lg"
      onClick={() => navigate(ROUTE.home)}
    >
      <LucideChevronLeft />
      Retour
    </Button>
  );
};

export default BackButton;
