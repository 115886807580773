import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchHelloWorld = async () => {
  const response = await axios.get(`/`);
  return response.data;
};

export const useHelloWorldQuery = () => {
  return useQuery({
    queryKey: ["hello"],
    queryFn: fetchHelloWorld,
  });
};
