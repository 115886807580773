export const ROUTE = {
  home: "/",
  connectionHome: "/home",
  admin: {
    login: "/admin/login",
  },
  candidate: {
    accountCreation: "/candidate/account-creation",
    myExams: "/candidate/my-exams",
  },
};
