// https://github.com/prisma/prisma/issues/12504#issuecomment-1147356141

// Import original enum as type
import type { Sex as SexOrigin } from "@prisma/client";

// Guarantee that the implementation corresponds to the original type
export const Sex: { [k in SexOrigin]: k } = {
  MALE: "MALE",
  FEMALE: "FEMALE",
};

// Re-exporting the original type with the original name
export type Sex = SexOrigin;
