import logo from "@/assets/logo.png";
import { Button } from "@/components/atoms/Button";
import InputField from "@/components/molecules/InputField";
import { useState } from "react";
import { useHelloWorldQuery } from "../../../../api/helloWorld";

const HelloWorld = () => {
  const { isPending, isError, data, error } = useHelloWorldQuery();

  if (isPending) return <div>Loading hello world...</div>;
  if (isError) return <div>Error loading hello world : {error.message}</div>;
  return <div>{data && <pre>{JSON.stringify(data, null, 2)}</pre>}</div>;
};

function Login() {
  const [, setEmail] = useState("");
  const [, setPassword] = useState("");
  const [isLoggedIn] = useState(false);
  const [failedLogin] = useState(false);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img
        className="flex justify-center items-center"
        src={logo}
        alt="Description of the image"
      />
      <div className="flex flex-col items-start p-8 gap-8 rounded-lg border border-gray-200 bg-white shadow-sm">
        <div className="text-left text-xl leading-[28px] self-stretch">
          {" "}
          Se connecter
        </div>
        <InputField
          setter={setEmail}
          title="Adresse email"
          placeholder="mail@gmail.com"
        />
        <InputField
          setter={setPassword}
          title="Mot de passe"
          placeholder="**********"
        />
        <div className="flex flex-col gap-2 items-start self-stretch">
          <Button className="flex justify-center gap-1 self-stretch border rounded-lg">
            <div className="text-[#1E55AF] font-semibold text-base leading-6">
              Mot de passe oublié
            </div>
          </Button>
          <Button
            className="flex px-1 py-2 justify-center gap-1 self-stretch rounded-lg border border-[#1D64D8] bg-[#2570EB]"
            onClick={() => console.log("trying to log in")} // TODO: login query
          >
            Se connecter
          </Button>
          <div>
            You are {isLoggedIn ? "logged in" : "not logged in"}
            {failedLogin && <div>Failed to login</div>}
          </div>
          <HelloWorld />
        </div>
      </div>
    </div>
  );
}

export default Login;
